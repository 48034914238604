import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import resolveConfig from "tailwindcss/resolveConfig";
import { Accordion, CustomFlowbiteTheme, Flowbite } from "flowbite-react";

import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

const theme: CustomFlowbiteTheme = {
  accordion: {
    title: {
      flush: {
        off: "",
      },
      open: {
        on: "text-white font-semibold bg-primary-blue-500 hover:bg-primary-blue-600",
        off: "text-default",
      },
    },
    content: {
      base: "!border-b border-solid p-4",
    },
  },
};

const HelpPage = () => {
  return (
    <Flowbite theme={{ theme }}>
      <MenuPageLayout title="Help">
        <div className="flex flex-col gap-12 w-full max-w-screen-lg">
          <div className="flex flex-col gap-4">
            <p>
              To open a support ticket or submit feedback, please visit our{" "}
              <a
                href="https://singandspeak4kids.com/contact-us/"
                className="text-blue-500 hover:text-blue-600"
                target="_blank"
                rel="noreferrer"
              >
                Help Desk.
              </a>
            </p>

            <p>
              <a
                href="#training-videos"
                className="text-blue-500 hover:text-blue-600"
                onClick={(e) => {
                  e.preventDefault();

                  const target = document.getElementById("training-videos");
                  const container =
                    document.getElementsByTagName("ion-content")[0].children[0];

                  if (!target) return;

                  console.log(
                    fullConfig.theme.spacing["4"],
                    fullConfig.theme.fontSize["base"],
                  );

                  container.scrollTo({
                    top: target.getBoundingClientRect().top - 16, // 16 is 1rem, based on the default font size of 16px.
                    behavior: "smooth",
                  });
                }}
              >
                Watch Training Videos
              </a>
            </p>
          </div>

          <section className="flex flex-col gap-4">
            <h2 className="text-2xl">FAQ</h2>

            <Accordion collapseAll>
              <Accordion.Panel>
                <Accordion.Title>
                  Why is it important to watch the training videos before
                  playing with my child?
                </Accordion.Title>

                <Accordion.Content>
                  <ol className="list-decimal list-inside flex flex-col gap-2">
                    <li>
                      Understanding How to Play SS4Kids: Learn how the game
                      works, making it easier to navigate and enjoy the game.
                    </li>

                    <li>
                      Effective Guidance: Adults learn how to guide the child
                      through the game.
                    </li>

                    <li>
                      Maximizing Learning: Make the educational aspects of the
                      game more effective.
                    </li>

                    <li>
                      Problem Solving: Know how to handle common issues that
                      might come up during gameplay.
                    </li>

                    <li>Feature Familiarity: Learn about all settings.</li>
                    <li>
                      Optimized Experience: Learn how to set up the game
                      correctly, providing a better overall experience for the
                      child.
                    </li>
                  </ol>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  How can I preview the songs before playing the game?
                </Accordion.Title>

                <Accordion.Content>
                  Each song has a preview link for more information, including
                  listening to the song, lyrics, vocab words, and reports. You
                  can see and hear the entire song before your child plays the
                  game.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>What do the symbols mean?</Accordion.Title>

                <Accordion.Content>
                  <ul className="flex flex-col gap-4">
                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="music" size="lg" />
                      </div>
                      Songs screen: Choose a song to play.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="comment" size="lg" />
                      </div>
                      Vocab Words screen: Review vocabulary words.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="chart-bar" size="lg" />
                      </div>
                      Reports screen: Review reports.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="cog" size="lg" />
                      </div>
                      Settings screen: Change play options.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="user" size="lg" />
                      </div>
                      My Account screen: Review your information.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="info" size="lg" />
                      </div>
                      Information screen: Learn more about the game.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="question" size="lg" />
                      </div>
                      Help screen: Get help on how to use the game.
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 text-white bg-primary-blue-400 rounded-full">
                        <FontAwesomeIcon icon="redo-alt" size="lg" />
                      </div>
                      Repeat the song (Level 1 and 2)
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-primary-green-400 rounded-full">
                        <FontAwesomeIcon icon="arrow-right" size="lg" />
                      </div>
                      Continue to the next level (Level 1 and 2)
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-red-400 rounded-full">
                        <FontAwesomeIcon icon="times" size="lg" />
                      </div>
                      No
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-primary-green-400 rounded-full">
                        <FontAwesomeIcon icon="check" size="lg" />
                      </div>
                      Yes
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="redo" size="lg" />
                      </div>
                      Restart the song
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="pause" size="lg" />
                      </div>
                      Pause the song
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="caret-left" size="lg" />
                      </div>
                      Previous level
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="caret-right" size="lg" />
                      </div>
                      Next level - SKIP
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-gray-200 rounded-full">
                        <FontAwesomeIcon icon="minus" size="lg" />
                      </div>
                      Assessment Question - No Response
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-red-400 rounded-full">
                        <FontAwesomeIcon icon="times" size="lg" />
                      </div>
                      Assessment Question - Incorrect
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-orange-300 rounded-full">
                        <div className="text-3xl font-semibold">~</div>
                      </div>
                      Assessment Question - Approximation
                    </li>

                    <li className="flex gap-2 items-center">
                      <div className="flex items-center justify-center size-12 bg-primary-green-400 rounded-full">
                        <FontAwesomeIcon icon="check" size="lg" />
                      </div>
                      Assessment Question - Correct
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>How do I SKIP a level?</Accordion.Title>

                <Accordion.Content>
                  During levels 3-14 of the first playthrough, if the child
                  responds with 4 &quot;Nos,&quot; the SKIP button will appear.
                  This option can be changed under &quot;Settings.&quot; After
                  completing all levels once, the SKIP button is always
                  available.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  How can I change the speed on each song?
                </Accordion.Title>

                <Accordion.Content>
                  Under settings, you can choose the speed of the song: NORMAL,
                  SLOWER, or SLOWEST.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  (Parent) How do I invite a professional (teacher/clinician) to
                  play with my child?
                </Accordion.Title>

                <Accordion.Content>
                  To invite a professional to play with your child, get their
                  email address. Log into SS4Kids, go to &quot;My Account,&quot;
                  and select &quot;Professionals Working With My Child.&quot;
                  Fill out the form under &quot;Authorize Additional
                  Professional(s)&quot; and submit it. The professional will get
                  an email with instructions on how to register and accept the
                  invite. Once they accept, they can see your child&apos;s data,
                  including progress reports. For help, contact
                  SS4KidsSupport@iqsonics.com.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  (Professional) How do I play SS4Kids with a parent&apos;s
                  child?
                </Accordion.Title>

                <Accordion.Content>
                  <ol className="flex flex-col gap-4">
                    <li>
                      STEP 1: Invite a parent to join SS4Kids by filling out
                      THIS FORM.
                    </li>

                    <li>
                      STEP 2: The parent needs to register and send you an
                      invite/consent to play.
                    </li>

                    <li>
                      STEP 3: Follow the directions in the invite/consent email.
                      If you didn&apos;t get the email, log in, go to
                      &quot;Account&quot;, and select &quot;Professional
                      Invites.&quot; Accept the invite.
                    </li>

                    <li>
                      STEP 4: To start playing, log in, go to &quot;My
                      Account,&quot; choose &quot;Child Info,&quot; select the
                      child&apos;s name, and click &quot;Switch.&quot;
                    </li>

                    <li>
                      STEP 5: Go to the &quot;Songs&quot; page to start playing
                      with the child. When done, go back to &quot;Child
                      Info,&quot; select &quot;No Child,&quot; and click
                      &quot;Switch&quot;.
                    </li>
                  </ol>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  How do I switch between multiple children in the game?
                </Accordion.Title>

                <Accordion.Content>
                  When you log in, the last child you played with is selected.
                  To switch, go to &quot;My Account,&quot; select the
                  &quot;Child Info&quot; tab, choose the child&apos;s name from
                  the drop-down list, and click &quot;Switch.&quot;
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  Where do I go to see the progress of my child?
                </Accordion.Title>

                <Accordion.Content>
                  To see progress by song, go to the &quot;Songs&quot; page,
                  click the &quot;i&quot; icon next to the song, and select the
                  &quot;Reports&quot; tab. For overall progress, go to the
                  &quot;Reports&quot; page.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  (Professional) How do I see the progress reports of a
                  parent&apos;s child?
                </Accordion.Title>

                <Accordion.Content>
                  You need to be invited and accept the invite. Log in, go to
                  &quot;My Account,&quot; select &quot;Child Info,&quot; choose
                  the child&apos;s name, and click &quot;Switch.&quot; Then, go
                  to the &quot;Reports&quot; page to see graphs. For individual
                  song progress, go to the &quot;Songs&quot; page, click the
                  &quot;i&quot; icon, and switch to the &quot;Reports&quot; tab.
                  When done, go back to &quot;Child Info,&quot; select &quot;No
                  Child,&quot; and click &quot;Switch.&quot;
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>What does Song Play mean?</Accordion.Title>

                <Accordion.Content>
                  Song Play is the time when you and the child are playing the
                  game. It starts when you click on a song and ends when you
                  finish all levels or leave the game (log out).
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>What does Word Learned mean?</Accordion.Title>

                <Accordion.Content>
                  <div className="flex flex-col gap-4">
                    <p>
                      A word is considered learned if the child speaks the word
                      successfully after the semantic question portion of the
                      game. (If the prompt is needed on the third try, then the
                      word is not yet considered learned.)
                    </p>

                    <p>
                      The semantic questions are asked from levels 16 through
                      21.
                    </p>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>What does Song Complete mean?</Accordion.Title>

                <Accordion.Content>
                  A song is complete when all 27 levels are played. The song
                  image will have a dark blue border. If the child plays again,
                  the color stays dark blue, and the level indicator shows the
                  last level played inside the song play.
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  What are Pre and Post-Assessment Options?
                </Accordion.Title>

                <Accordion.Content>
                  <div>
                    <p>
                      Basic Progress Tracking includes reports and tracking from
                      levels 1-21. Advanced Progress Tracking adds assessments
                      before and after using the vocab words in the song. You
                      can change this under &quot;Settings.&quot; Coordinate
                      with a teacher or clinician if using Advanced Progress
                      Tracking.
                    </p>

                    <p>
                      Starting a song with Advance Progress Tracking off, you
                      will not be able to turn it on later.
                    </p>

                    <p>
                      If Advance Progress Tracking was on when you start a song
                      for the very first time, then it can be toggled on or off
                      at any time thereafter. Watch the training video for more
                      details.
                    </p>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  How do I cancel/pause my membership?
                </Accordion.Title>

                <Accordion.Content>
                  Go to &quot;Account Info&quot; (button with a person) and
                  click on &quot;Cancel my Membership&quot; in orange. Follow
                  the steps to cancel.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </section>

          <section className="flex flex-col gap-4" id="training-videos">
            <h2 className="text-2xl">Training Videos</h2>

            <div className="grid lg:grid-cols-3 gap-8">
              <div className="flex flex-col gap-4">
                <iframe
                  src="https://player.vimeo.com/video/455663190?title=0&amp;byline=0&amp;portrait=0&amp;transparent=0"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>

                <h3>Let&apos;s Get Started!</h3>

                <p>
                  Let&apos;s get started and learn about what&apos;s needed
                  first, prep, room setting, log in, repetition, going at the
                  child&apos;s pace, staying positive, home activities and
                  family engagement. And have fun!!
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <iframe
                  src="https://player.vimeo.com/video/459147719?title=0&amp;byline=0&amp;portrait=0&amp;transparent=0"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>

                <h3>How to Play the Game</h3>

                <p>Watch how to play the game step-by-step.</p>
              </div>

              <div className="flex flex-col gap-4">
                <iframe
                  src="https://player.vimeo.com/video/464859499?title=0&amp;byline=0&amp;portrait=0&amp;transparent=0"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>

                <h3>Pre and Post-Assessment Options</h3>

                <p>
                  Learn about Pre and Post-Assessment options, and how to assess
                  vocab words in the Pre and Post-Assessments. Decide with your
                  teacher/clinician if Pre and Post-Assessments are appropriate
                  for your child.
                </p>
              </div>
            </div>
          </section>

          <section className="flex flex-col gap-4">
            <h2 className="text-2xl">About</h2>

            <p>
              <em>Sing and Speak 4 Kids</em> is a product of{" "}
              <a
                href="https://singandspeak4kids.com/about-us/"
                className="text-blue-500 hover:text-blue-600"
                target="_blank"
                rel="noreferrer"
              >
                iQsonics
              </a>
              .
            </p>
            <p>Version 2.0.0</p>
          </section>

          <section className="flex flex-col gap-4">
            <h2 className="text-2xl">Contact</h2>

            <ul className="flex flex-col gap-4">
              <li>
                Support:{" "}
                <a
                  href="mailto:SS4KidsSupport@iqsonics.com"
                  className="text-blue-500 hover:text-blue-600"
                >
                  SS4KidsSupport@iqsonics.com
                </a>
              </li>
              <li>
                Information:{" "}
                <a
                  href="mailto:SS4Kids@iqsonics.com"
                  className="text-blue-500 hover:text-blue-600"
                >
                  SS4Kids@iqsonics.com
                </a>
              </li>
            </ul>
          </section>

          <section className="flex flex-col gap-4">
            <h2 className="text-2xl">Science / Art</h2>

            <p>
              Read more about the science and art of{" "}
              <a
                href="https://singandspeak4kids.com/research/"
                className="text-blue-500 hover:text-blue-600"
                target="_blank"
                rel="noreferrer"
              >
                Sing and Speak 4 Kids
              </a>
              .
            </p>
          </section>

          <section className="flex flex-col gap-4">
            <h2 className="text-2xl">Credits</h2>

            <ol className="flex flex-col gap-4">
              <li>David Sonnenschein - CEO, Game Designer, Music Composer</li>
              <li>Hayoung Lim, PhD - Neurologic Music Therapist</li>
              <li>Kyle Hillbrand - Music Composer</li>
              <li>Jahna Perricone - Vocal Talent</li>
              <li>Daniel Dye - Software Developer</li>
              <li>Rob Arbittier - Chief Technology Officer</li>
              <li>Annie Goeke - Chief Marketing Officer</li>
              <li>Katelyn Miller - Marketing and Outreach</li>
              <li>
                Vocabulary word stock photos - Pixabay.com, Pexels.com, Adobe
                Express
              </li>
              <li>
                Animated Rewards - Joel (Gnome, Mouse), Joanne (Fireworks, Pig,
                Bear), cliply.co (Rocket, Kitty, Present, Bus, Confetti)
              </li>
            </ol>
          </section>
        </div>
      </MenuPageLayout>
    </Flowbite>
  );
};

export default HelpPage;
